
import Css from "./style.module.scss";

import { Route, Switch, useHistory } from "react-router-dom";
import { checkAuthState, checkIsBusinessUser } from "selectors/user";
import { getActiveOrganization } from "selectors/organizations";
import { getRouterData } from "selectors/router";
import { getSelectedBusinessData, getUserBusinessesData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useDispatch, useSelector } from "react-redux";
import AuthHandler from "nlib/common/AuthHandler";
import Businesses from "utils/Businesses";
import BusinessesActions from "actions/BusinessesActions";
import Constants from "const/Constants";
import Countries from "const/Countries";
import GlobalTooltip from "nlib/common/GlobalTooltip";
import Header from "nlib/Header";
import IntegrationServices from "const/IntegrationServices";
import IntegrationsActions from "actions/IntegrationsActions";
import LoginRedirect from "nlib/common/LoginRedirect";
import ModalsContainer from "nlib/common/ModalsContainer";
import Navbar from "nlib/Navbar";
import NoBusinessData from "./lib/NoBusinessData";
import Onboarding from "nlib/common/Onboarding";
import OrganizationsActions from "actions/OrganizationsActions";
import Preloader from "nlib/ui/Preloader";
import React, { Suspense, lazy, useCallback, useEffect, useMemo, useState } from "react";
import RedirectToDefaultRoute from "nlib/common/RedirectToDefaultRoute";
import SidePanel from "nlib/SidePanel";
import UiActions from "actions/UiActions";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";

const SummaryPage = lazy(() => import("nlib/pages/SummaryPage"));

const SettingsPage = lazy(() => import("nlib/pages/SettingsPage"));

const ROUTES = [
  [UiRoutes.AUDIT, lazy(() => import("nlib/pages/AuditPage"))],
  [UiRoutes.TRANSACTIONS, lazy(() => import("nlib/pages/TransactionsPage"))],
  [UiRoutes.DOCUMENTS, lazy(() => import("nlib/pages/DocumentsPage"))],
  [UiRoutes.TASKS, lazy(() => import("nlib/pages/TasksPage"))],
  [UiRoutes.DASHBOARD, lazy(() => import("nlib/pages/DashboardPage"))],
  [UiRoutes.ACTIVITY, lazy(() => import("nlib/pages/ActivityPage"))],
  [UiRoutes.ARCHIVE, lazy(() => import("nlib/pages/VaultPage"))],
  [UiRoutes.CONTACTS, lazy(() => import("nlib/pages/ContactsPage"))],
  [UiRoutes.ACCOUNTS, lazy(() => import("nlib/pages/AccountsPage"))]
];

const NewApp = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { messages, uiTexts } = useSelector(getTextsData);

  const userAuthState = useSelector(checkAuthState);

  const businessesData = useSelector(getUserBusinessesData);

  const selectedBusinessData = useSelector(getSelectedBusinessData);

  const businessUser = useSelector(checkIsBusinessUser);

  const activeOrganization = useSelector(getActiveOrganization);

  const routerData = useSelector(getRouterData);

  const [routerSeed, setRouterSeed] = useState(routerData.location.state?.seed);

  const { countryCode } = activeOrganization || {};

  const newBusinessRpaMode = useMemo(() => {
    return !!(activeOrganization && Utils.storageValue(
      `${Constants.LS_KEYS.NEW_BUSINESS_RPA_MODE}_${activeOrganization.id}`,
      undefined,
      true
    ));
  }, [activeOrganization]);

  const checkBusinessIdValid = useCallback((businessId) => {
    return businessId && businessesData.some(({ id }) => id === businessId);
  }, [businessesData]);

  const onAddBusiness = useCallback(async(result) => {
    let manualIntegrationFlow = false;

    let business;

    if (result) {
      const { integrationService, name, regId, type, vatPayer } = result;

      dispatch(UiActions.togglePreloader(true));
      if (integrationService) {
        if (integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value) {
          manualIntegrationFlow = true;
          dispatch(UiActions.togglePreloader(false));

          dispatch(UiActions.showAddQuickBooksDesktopWindow());
        } else {
          const consentUrl = await dispatch(IntegrationsActions.buildConsentUrl(integrationService));

          if (consentUrl) {
            window.location.href = consentUrl;

            return;
          } dispatch(UiActions.togglePreloader(false));
        }
      } else {
        business = await dispatch(BusinessesActions.addNewBusiness(name, regId, type, vatPayer));

        if (business) {
          await dispatch(OrganizationsActions.fetchOrganizationsList());

          history.push(`/${business.id}`);
        }

        dispatch(UiActions.togglePreloader(false));
      }
    }
    if (!manualIntegrationFlow && !businessesData.length && !business) {
      await dispatch(UiActions.showModal(messages.addFirstBusiness, uiTexts.warning));
    }
  }, [businessesData.length, dispatch, history, messages, uiTexts]);

  useEffect(() => {
    dispatch(UiActions.togglePreloader(false));
  }, [dispatch]);

  useEffect(() => {
    if (routerData.location.state?.seed) setRouterSeed(routerData.location.state.seed);
  }, [routerData.location.state?.seed]);

  return (
    <>
      <div className={Css.app}>
        <Switch>
          <Route path={UiRoutes.LOGIN}>{() => userAuthState ? <RedirectToDefaultRoute /> : <AuthHandler />}</Route>
          <Route path={`${UiRoutes.INTEGRATIONS}/:service`}>
            {({ match: { params: { service } } }) => {
              return userAuthState && !businessUser
                ? (
                  <>
                    <Header onboarding hideIntroButton />
                    <Onboarding service={service} rpaMode={newBusinessRpaMode} onAddBusiness={onAddBusiness} />
                  </>
                )
                : <LoginRedirect />;
            }}
          </Route>
          <Route path={`${UiRoutes.SETTINGS}/:section?`}>
            {userAuthState
              ? (
                <>
                  <Header />
                  <Suspense fallback={null}>
                    <SettingsPage />
                  </Suspense>
                </>
              )
              : <LoginRedirect />}
          </Route>
          <Route path="/:businessId?">
            {({ match: { params: { businessId } } }) => {
              if (!userAuthState) return <LoginRedirect />;

              if (businessId && !checkBusinessIdValid(businessId)) {
                return <RedirectToDefaultRoute />;
              }

              if (!businessesData.length) {
                return (
                  <>
                    <Header onboarding hideIntroButton />
                    <NoBusinessData onAddBusiness={onAddBusiness} />
                  </>
                );
              }

              if (!businessId && !businessUser) {
                return (
                  <>
                    <Header />
                    <Suspense fallback={<Preloader fixed />}>
                      <SummaryPage />
                    </Suspense>
                  </>
                );
              }

              if (businessId !== selectedBusinessData.id) {
                return <Preloader absolute />;
              }

              const { extraData, settings } = selectedBusinessData;

              const onboarding = countryCode !== Countries.CZ
                && !businessUser && Businesses.checkIsOnboarding(extraData, settings);

              return (
                <div className={Css.outer}>
                  {!onboarding && <Navbar hideIntroButton={onboarding} />}
                  {onboarding
                    ? (
                      <div className={Css.inner}>
                        <Header onboarding={onboarding} />
                        <Onboarding rpaMode={Businesses.checkIsRpaMode(extraData)} onAddBusiness={onAddBusiness} />
                      </div>
                    )
                    : (
                      <div className={Css.inner}>
                        <Header />
                        <div className={Css.wrap}>
                          <div className={Css.page}>
                            <Suspense fallback={<Preloader className={Css.preloader} />}>
                              <Switch>
                                {ROUTES.map(([route, PageComponent]) => (
                                  <Route
                                    key={`${route}/${businessId}/${routerSeed}`}
                                    path={`/:businessId${route}`}
                                    component={PageComponent} />
                                ))}
                                <Route><RedirectToDefaultRoute /></Route>
                              </Switch>
                            </Suspense>
                          </div>
                          <SidePanel />
                        </div>
                      </div>
                    )}
                </div>
              );
            }}
          </Route>
        </Switch>
        <ModalsContainer />
      </div>
      <GlobalTooltip />
    </>
  );
};

export default React.memo(NewApp);
