import ParentCss from "../../style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActivityData } from "selectors/activity";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityEvents from "const/ActivityEvents";
import React, { useMemo } from "react";
import Step from "../Step";
import useActivityEventDescription from "hooks/useActivityEventDescription";

const RPA_ERRORS = [
  ActivityEvents.TYPES.GPT_AI_FINE_TUNE_FAILED,
  ActivityEvents.TYPES.RPA_FAILED
];

const RpaSettingUpStep = ({ index, currentStep }) => {
  const { messages } = useSelector(getTextsData);

  const activityData = useSelector(getActivityData);

  const rpaErrorData = useMemo(() => {
    const failedActivityEvents = activityData
      .sort(({ timestamp: timestampA }, { timestamp: timestampB }) => timestampB - timestampA)
      .map((event) => RPA_ERRORS.includes(event.type) ? event : null);

    return failedActivityEvents[0] || null;
  }, [activityData]);

  const rpaErrorDescription = useActivityEventDescription(rpaErrorData || {});

  return (
    <Step
      number={index + 1}
      className={ParentCss.stepFifth}
      error={!!rpaErrorData}
      active={currentStep === index}
      success={currentStep > index}>
      <div className={ParentCss.subtitle}>{messages.onboardingRpaSettingUp}</div>
      {currentStep === index && (
        <div className={`${ParentCss.progress} ${ParentCss.animatedInfinity}`}><div /></div>
      )}
      {(currentStep === index || rpaErrorData) && (
        rpaErrorData
          ? (
            <div className={ParentCss.error}>
              <Icons.Warning />
              <span className={ParentCss.text}>{rpaErrorDescription}</span>
            </div>
          )
          : (
            <div className={ParentCss.text}>
              {messages.onboardingRpaSetUpDescription}
            </div>
          )
      )}
    </Step>
  );
};

export default React.memo(RpaSettingUpStep);
