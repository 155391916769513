import Css from "./style.module.scss";

import { Portal, Tooltip } from "nlib/ui";
import React, { useCallback, useEffect, useRef, useState } from "react";

const MARGIN = 8;

const GlobalTooltip = () => {
  const [text, setText] = useState(null);

  const [style, setStyle] = useState({});

  const timeoutRef = useRef(null);

  const handleWindowEnter = useCallback((event) => {
    setText(null);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (event.target.dataset?.tooltip) {
      const rect = event.target.getBoundingClientRect();

      const animationDuration = event.target.dataset.tooltipDelay;

      timeoutRef.current = setTimeout(() => {
        setText(event.target.dataset.tooltip);
        setStyle({
          top: rect.top - MARGIN,
          // eslint-disable-next-line no-magic-numbers
          left: rect.left + (rect.width / 2),
          bottom: "auto"
        });
      }, +animationDuration || 0);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("mouseover", handleWindowEnter);
    window.addEventListener("click", handleWindowEnter);

    return () => {
      window.removeEventListener("mouseover", handleWindowEnter);
      window.removeEventListener("click", handleWindowEnter);
    };
  }, [handleWindowEnter]);

  if (!text) return null;

  return (
    <Portal>
      <Tooltip opened className={Css.tooltip} style={style}>{text}</Tooltip>
    </Portal>
  );
};

export default React.memo(GlobalTooltip);
