import ParentCss from "../../style.module.scss";

import Css from "./style.module.scss";

import { getTextsData } from "selectors/texts";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import React, { Fragment, useCallback } from "react";
import Step from "../Step";

const QuickBooksCompleteStep = ({ newBusinessId }) => {
  const history = useHistory();

  const { messages } = useSelector(getTextsData);

  const [
    onboardingSyncCompleteForQboA,
    onboardingSyncCompleteForQboB,
    onboardingSyncCompleteForQboC,
    onboardingSyncCompleteForQboD,
    onboardingSyncCompleteForQboE,
    onboardingSyncCompleteForQboF,
    onboardingSyncCompleteForQboG,
    onboardingSyncCompleteForQboH
  ] = messages.onboardingSyncCompleteForQbo;

  const handleButtonClick = useCallback(() => {
    history.push(`/${newBusinessId}`);
  }, [history, newBusinessId]);

  return (
    <Step active className={Css.step}>
      <div>
        <div className={Css.subtitle}>{onboardingSyncCompleteForQboA}</div>
        <div className={ParentCss.row}>
          <div className={ParentCss.col}>
            <div className={Css.content}>
              <div className={Css.text}>
                <ol>
                  <li>{onboardingSyncCompleteForQboB}</li>
                  <li>{onboardingSyncCompleteForQboC}</li>
                  <li>{onboardingSyncCompleteForQboD}</li>
                  <li>{onboardingSyncCompleteForQboE}</li>
                  <div>
                    {onboardingSyncCompleteForQboF.split("*").map((str, idx) => {
                      return (
                        <Fragment key={String(idx + 1)}>
                          {idx % "2" ? <b>{str}</b> : <span>{str}</span>}
                        </Fragment>
                      );
                    })}
                  </div>
                </ol>
                <div>{onboardingSyncCompleteForQboG}</div>
              </div>
            </div>
          </div>
          <Button
            primary large
            className={ParentCss.likeButton}
            onClick={handleButtonClick}>
            {onboardingSyncCompleteForQboH}
          </Button>
        </div>
      </div>
    </Step>
  );
};

export default React.memo(QuickBooksCompleteStep);
