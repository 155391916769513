import Css from "./style.module.scss";

import coloredLogo from "assets/coloredLogo.svg";

import extensionLogo from "assets/extensionIcon.svg";

import * as Icons from "@phosphor-icons/react";
import { Button } from "nlib/ui";
import { NavLink, useRouteMatch } from "react-router-dom";
import { checkFetchingData } from "selectors/data";
import { checkIsBusinessUser } from "selectors/user";
import {
  checkSelectedBusinessHasBusinessUsers,
  checkSelectedBusinessRpaMode,
  getCurrentQuickBooksRealmId,
  getCurrentXeroOrganizationId,
  getGlobalStats,
  getSelectedBusinessData,
  getUserBusinessesData
} from "selectors/businesses";
import { getActiveOrganization, getProjectName } from "selectors/organizations";
import { getContactsData } from "selectors/contacts";
import { getTextsData } from "selectors/texts";
import { getUploadingArchiveFilesCount } from "selectors/archive";
import { getUploadingDocumentsCount } from "selectors/documents";
import { useSelector } from "react-redux";
import Badge from "nlib/ui/Badge";
import Countries from "const/Countries";
import Features from "const/Features";
import IntegrationServices from "const/IntegrationServices";
import Pages from "nlib/pages/Pages";
import React, { useEffect, useMemo, useState } from "react";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import useAllowedFeature from "hooks/useAllowedFeature";

const { CHROME_BROWSER_EXTENSION_URL } = Utils.getProcessEnvVars();

const { QUICK_BOOKS, XERO } = IntegrationServices;

const {
  ACCOUNTS,
  ACTIVITY,
  ARCHIVE,
  AUDIT,
  CONTACTS,
  DASHBOARD,
  DOCUMENTS,
  TASKS,
  TRANSACTIONS
} = UiRoutes;

const Navbar = () => {
  const fetchingData = useSelector(checkFetchingData);

  const { params: { currentRoute } = {} } = useRouteMatch("/:businessId?/:currentRoute?");

  const { businessOrganization, countryCode } = useSelector(getActiveOrganization);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const projectName = useSelector(getProjectName);

  const { uiTexts, messages } = useSelector(getTextsData);

  const globalStatsData = useSelector(getGlobalStats);

  const documentsCurrentUploadCount = useSelector(getUploadingDocumentsCount);

  const archiveFilesCurrentUploadCount = useSelector(getUploadingArchiveFilesCount);

  const contactsData = useSelector(getContactsData);

  const [firstContact] = contactsData;

  const quickBooksBusiness = !!useSelector(getCurrentQuickBooksRealmId);

  const xeroBusiness = !!useSelector(getCurrentXeroOrganizationId);

  const {
    id: selectedBusinessId,
    extraData: { integrationService } = {}
  } = useSelector(getSelectedBusinessData);

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const businessUser = useSelector(checkIsBusinessUser);

  const checkIsAllowed = useAllowedFeature();

  const userBusinesses = useSelector(getUserBusinessesData);

  const activeOrganization = useSelector(getActiveOrganization);

  const [showExtension, setShowExtension] = useState(false);

  const extensionLink = navigator.userAgent.includes("Chrome") ? CHROME_BROWSER_EXTENSION_URL : (
    navigator.userAgent.includes("Safari") ? null : null
  );

  const singleBusiness = userBusinesses.length === 1;

  const czCountry = countryCode === Countries.CZ;

  const { value: serviceValue } = IntegrationServices.getByValue(integrationService) || {};

  const { whiteLabel: { logoUrl } = {} } = activeOrganization || {};

  const pagesList = useMemo(() => {
    return Object
      .values(Pages.getPageData(undefined, businessUser))
      .filter(({ value }) => {
        switch (value) {
          case TASKS:
            return checkIsAllowed(Features.TASKS)
              && (!businessOrganization || selectedBusinessHasBusinessUsers);
          case AUDIT:
            return !businessUser && (quickBooksBusiness || xeroBusiness);
          case DASHBOARD:
          case ACTIVITY:
            return !businessUser;
          case CONTACTS:
            return !businessUser && firstContact && !firstContact.short;
          case ACCOUNTS:
            return czCountry;
          case TRANSACTIONS:
            return !czCountry;
          default:
            return true;
        }
      });
  }, [
    czCountry,
    businessUser,
    checkIsAllowed,
    firstContact,
    businessOrganization,
    selectedBusinessHasBusinessUsers,
    quickBooksBusiness,
    xeroBusiness
  ]);

  const pageRows = useMemo(() => {
    return pagesList.map((pageData) => {
      const {
        value: route,
        disabled,
        titleLangId,
        urlParameters,
        statsNotificationProp,
        statsNotificationUrlParameters,
        iconComponent: Icon
      } = pageData;

      const statsNotification = Utils.getPropertyByPath(globalStatsData, statsNotificationProp);

      const searchString = (
        statsNotification && statsNotificationUrlParameters
      ) ? Utils.objectToQueryString({ ...urlParameters, ...statsNotificationUrlParameters }) : "";

      const uploading = (route === DOCUMENTS && documentsCurrentUploadCount)
        || (route === ARCHIVE && archiveFilesCurrentUploadCount);

      const badgeValue = uploading || statsNotification;

      const pathData = {
        pathname: `/${selectedBusinessId}${route}`,
        search: searchString ? `?${searchString}` : "",
        state: currentRoute === route.slice(1) ? { seed: Math.random() } : null
      };

      return (
        <NavLink
          key={route}
          disabled={disabled || fetchingData}
          className={classNames(Css.navItem, uploading && Css.uploading)}
          activeClassName={Css.active}
          to={pathData}>
          <div className={Css.content}>
            <div className={Css.icon}>
              {uploading ? <Icons.CloudArrowUp data-warning /> : <Icon />}
            </div>
            <span className={Css.text}>
              <span>{uiTexts[titleLangId]}</span>
            </span>
            {!!badgeValue && (
              <Badge counter theme="attention" className={Css.notification}>
                {badgeValue}
              </Badge>
            )}
          </div>
        </NavLink>
      );
    });
  }, [
    globalStatsData,
    documentsCurrentUploadCount,
    archiveFilesCurrentUploadCount,
    selectedBusinessId,
    currentRoute,
    uiTexts,
    fetchingData,
    pagesList
  ]);

  useEffect(() => {
    setShowExtension(
      !businessUser
        && !Utils.checkIsTouchDevice()
        && !rpaMode
        && !czCountry
        && extensionLink
        && [QUICK_BOOKS.value, XERO.value].includes(serviceValue)
    );
  }, [businessUser, rpaMode, extensionLink, czCountry, serviceValue]);

  return (
    <div className={Css.navbar}>
      <div className={Css.inner}>
        <div className={Css.title}>
          <div className={Css.logo} title={projectName}>
            <img src={logoUrl || coloredLogo} />
          </div>
        </div>
        {!businessUser && !singleBusiness && (
          <a
            disabled={fetchingData}
            href={UiRoutes.MAIN}
            className={Css.navItem}
            title={uiTexts.toAllClients}>
            <div className={Css.content}>
              <div className={Css.icon}>
                <Icons.ArrowLeft />
              </div>
              <span className={Css.text}>
                <span>{uiTexts.toAllClients}</span>
              </span>
            </div>
          </a>
        )}
        {pageRows}
        {showExtension && (
          <a className={Css.extension} target="_blank" href={extensionLink}>
            <div className={Css.wrap}>
              <div className={Css.icon}>
                <img src={extensionLogo} title={uiTexts.browserExtension} />
              </div>
              <div className={Css.title}>{uiTexts.browserExtension}</div>
              <div className={Css.description}>{messages.extensionDescription}</div>
              <Button primary className={Css.button}>
                <Icons.DownloadSimple />
                <span>{uiTexts.install}</span>
              </Button>
            </div>
          </a>
        )}
      </div>
    </div>
  );
};

export default React.memo(Navbar);
