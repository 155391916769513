import ParentCss from "../../style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getSelectedBusinessData } from "selectors/businesses";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import Button from "nlib/ui/Button";
import IntegrationServices from "const/IntegrationServices";
import React, { useCallback, useMemo } from "react";
import Step from "../Step";
import Utils from "utils/Utils";
import classNames from "classnames";

const Warning = ({ active, connectedService, rpaMode, envVarsError, integrationService, integrationServiceConnected }) => {
  const { messages, errors } = useSelector(getTextsData);

  const { name: businessName } = useSelector(getSelectedBusinessData);

  const integrationServiceData = IntegrationServices.getByValue(integrationService);

  const quickBooksDesktopBusiness = integrationService === IntegrationServices.QUICK_BOOKS_DESKTOP.value;

  const [serviceIntegrationExpiredA, serviceIntegrationExpiredB] = quickBooksDesktopBusiness
    ? messages.quickBooksDesktopExpired : messages.serviceIntegrationExpired;

  if (connectedService && envVarsError) {
    return (
      <div className={ParentCss.error}>
        <Icons.Warning />
        <span className={ParentCss.text}>{errors.integrationIssue}</span>
      </div>
    );
  }
  if (!connectedService && integrationService && !integrationServiceConnected) {
    return (
      <div className={ParentCss.error}>
        <Icons.Warning />
        <span className={ParentCss.text}>
          <span>
            {Utils.replaceTextVars(serviceIntegrationExpiredA, {
              businessName,
              serviceName: integrationServiceData.label
            })}
          </span>
          <br />
          <span>
            {Utils.replaceTextVars(serviceIntegrationExpiredB, {
              businessName,
              serviceName: integrationServiceData.label
            })}
          </span>
        </span>
      </div>
    );
  }

  if (active && rpaMode) {
    return (
      <div className={ParentCss.warning}>
        <Icons.WarningCircle />
        <div className={ParentCss.text}>
          {messages.onboardingQboConnectWarning}
        </div>
      </div>
    );
  }

  return null;
};

const SelectServiceStep = (props) => {
  const {
    index,
    currentStep,
    connectedService,
    rpaMode,
    serviceToConnect,
    onSetServiceToConnect,
    onAddBusiness
  } = props;

  const active = currentStep === index;

  const { uiTexts } = useSelector(getTextsData);

  const {
    extraData: {
      integrationService,
      integrationServiceConnected
    } = {}
  } = useSelector(getSelectedBusinessData);

  const { error: envVarsError } = useMemo(() => {
    return Utils.parseQueryString(window.location.search);
  }, []);

  const handleIntegrationButtonClick = useCallback(({ currentTarget: { dataset } }) => {
    if (dataset.service === IntegrationServices.QUICK_BOOKS_DESKTOP.value) {
      onSetServiceToConnect(dataset.service);
    } else {
      onAddBusiness({ integrationService: dataset.service, rpaMode });
    }
  }, [rpaMode, onAddBusiness, onSetServiceToConnect]);

  return (
    <Step
      number={index + 1}
      error={(connectedService && envVarsError)
        || (!connectedService && integrationService && !integrationServiceConnected)}
      active={active}
      success={currentStep > index}
      className={ParentCss.stepFirst}>
      <div className={ParentCss.row}>
        <div className={ParentCss.col}>
          <div className={ParentCss.subtitle}>{uiTexts.connectNewBussiness}</div>
          {active && !rpaMode && !integrationService && (
            <div className={ParentCss.buttons}>
              {IntegrationServices.getList()
                .filter(({ fullIntegration }) => fullIntegration)
                .map(({ value, label }) => (
                  <Button
                    outline large
                    key={value}
                    disabled={!!serviceToConnect}
                    loading={serviceToConnect === value}
                    data-service={value}
                    className={ParentCss.button}
                    onClick={handleIntegrationButtonClick}>
                    {serviceToConnect === value && <Icons.Spinner data-wait />}
                    <span>{label}</span>
                  </Button>
                ))}
            </div>
          )}
        </div>

        {active
          ? (rpaMode && (
            IntegrationServices.getList()
              .filter(({ value }) => value === connectedService || serviceToConnect)
              .map((service) => {
                return (
                  <Button
                    large primary
                    key={service.value}
                    disabled={!!serviceToConnect}
                    loading={serviceToConnect === service.value}
                    data-service={service.value}
                    className={classNames(ParentCss.button, ParentCss.likeButton)}
                    onClick={handleIntegrationButtonClick}>
                    {serviceToConnect === service.value && <Icons.Spinner data-wait />}
                    <span>{service.label}</span>
                  </Button>
                );
              })
          ))
          : (
            <div className={`${ParentCss.likeButton} ${ParentCss.success}`}>
              <Icons.Check weight="bold" />
              <span>{uiTexts.connected}</span>
            </div>
          )}

        {active && !rpaMode && integrationService && (
          IntegrationServices.getList()
            .filter(({ value }) => value === integrationService)
            .map(({ value, label }) => (
              <Button
                large primary
                key={value}
                disabled={!!serviceToConnect}
                loading={serviceToConnect === value}
                data-service={value}
                className={classNames(ParentCss.likeButton, ParentCss.button)}
                onClick={handleIntegrationButtonClick}>
                {serviceToConnect === value && <Icons.Spinner data-wait />}
                <span>{label}</span>
              </Button>
            ))
        )}
      </div>
      <Warning
        active={active}
        connectedService={connectedService}
        rpaMode={rpaMode}
        envVarsError={envVarsError}
        integrationService={integrationService}
        integrationServiceConnected={integrationServiceConnected} />
    </Step>
  );
};

export default React.memo(SelectServiceStep);
